import React                from 'react'
import { NavLink }          from 'react-router-dom';

import * as routes          from '../../constants/routes';

const LinkList = ({ clickResponse }) => {
    return(
        <>
            <NavLink className="nav-link" activeclassname="active" to={routes.ROOT} onClick={() => {clickResponse();}}>HOME</NavLink>
            <NavLink className="nav-link" activeclassname="active" to={routes.PORT} onClick={() => {clickResponse();}}>PORTFOLIO</NavLink>
            <NavLink className="nav-link" activeclassname="active" to={routes.SERV} onClick={() => {clickResponse();}}>SERVICES</NavLink>
            <NavLink className="nav-link" activeclassname="active" to={routes.INFO} onClick={() => {clickResponse();}}>ABOUT ME</NavLink>
            <NavLink className="nav-link" activeclassname="active" to={routes.MAIL} onClick={() => {clickResponse();}}>CONTACT</NavLink>
        </>
    );
};

export default LinkList;