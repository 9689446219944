import React, { Component}              from 'react'
import styled               from 'styled-components';

import * as routes          from '../../constants/routes';

import LinkList from './LinkList';

export default class HamburgerMenu extends Component {
    navClickResponse = () => {
        const { animateSlideMe, btnHamburger, toggleMenu } = this.props;
        toggleMenu(); 
        animateSlideMe(); 
        btnHamburger();
    }
    render(){
    return(
        <NavContainer id="menu" className="inactive">
            <div>
                <LinkList clickResponse={this.navClickResponse} />
            </div>
        </NavContainer>
    );
};

}
const NavContainer = styled.div`
    text-align: center;
    font-size: 20px;
    right: 0;
    z-index: 111;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(19,17,17,.9);
    color: #FFF;
    position: fixed;

    > div > a {
        display: block;
        height: 44px;
        padding-top: 20px;

        color: #FFF;
        font-weight: 400;
        letter-spacing: 2px;
        text-decoration: none;
    }
    a:hover {
        color: var(--hex-lightblue)!important;
    }
    div {
        padding-top: 140px;
    }
    button {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 5px;
        color: #FFF;
        border: none;
        font-size: 14px;
    }
`;
