import React        from 'react';
import styled       from 'styled-components';

// Insert card as independent function to enable scroll into view for each session
import ProjectCard  from './Project';

const Projects = ({ projects }) => {
    const liveProjects = projects.filter(project => (project.endDate === null && project.value === 'high'));
    const showProjects = liveProjects.map((project, key) => {
        return( <ProjectCard key={key} project={project}/> );
    });
    return(
        <Container>
            {showProjects}          
        </Container>
    );
};

const Container = styled.div`
    --_gap: 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;

    @media screen and (max-width: 900px) {
        padding: 0 12px;
    }
`;

export default Projects;