import React                from 'react';
import styled               from 'styled-components';

import AnimateText          from '../components/snippets/AnimateText';

import Projects             from '../components/sections/projects/Projects';
import MedProjects          from '../components/sections/projects-med/MedProjects';
import LowProjects          from '../components/sections/projects-low/LowProjects';
import PastProjects         from '../components/sections/projects-inactive/InactiveProjects';
import Footer               from '../components/Footer';

 const PortfolioPage = ({ projects }) => {
    return (
        <Container className="container">
            <h1 className="top-header header">
                <AnimateText animate="slide" fade="on" speed="fast" text="Projects" />
            </h1>
            <Projects projects={projects}/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>JOINT EFFORT</h1>
            <br/>
            <MedProjects projects={projects}/>
            <br/>
            <br/>
            <br/>
            <h1>SMALL PROJECTS</h1>
            <br/>
            <LowProjects projects={projects}/>
            <br/>
            <br/>
            <h1>PAST WORK</h1>
            <br/>
            <PastProjects projects={projects}/>
            <Footer/>      
        </Container>
    );
};

const Container = styled.div`
    @media screen and (max-width: 745px){
        padding-top: var(--section-padding);
    }
`;

export default PortfolioPage;