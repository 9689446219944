// PROJECT NOTES *************************************************************
// copy the DisplaySection example
// pass project instead, must map in parent.
// goak: when scroll into view, intersectionObserver triggers for each section

import React, { useEffect, useRef } from 'react';
// import React, { useEffect, useRef, useState } from 'react';
import styled                       from 'styled-components';

const ProjectCard = ({ project }) => {
    // Add intersection observer to the section 
    // Map in parent to create scroll into view effect
    // Save state example for future components
    const ref = useRef();
    // const [refState, setRefState] = useState();
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            // setRefState(entry.isIntersecting);
            if (entry.isIntersecting){
                entry.target.classList.remove('hide-unobserved');
                observer.disconnect();
            }
        });
        observer.observe(ref.current);
    }, []);

    const technologiesList = project.technologies.map((c, k) => {
        return <span key={k}>{c}</span>
    });
    const tasksList = project.tasks.map((r, k) => {
        return <li key={k}><span>{r}</span></li>
    });
    return(
        <Wrapper ref={ref} className={`hide-unobserved`}>
            <MobileImg href={`https://${project.domain}${project.ext}`} target="_blank" rel="noopener noreferrer">
                <img 
                    className="mobile-block" 
                    src={`./projects/${project.domain}.png`} 
                    alt={project.name}
                    loading="lazy" 
                    />
            </MobileImg>
            <section>
                <h2>{project.name}</h2>
                <a href={`https://${project.domain}${project.ext}`} target="_blank" rel="noopener noreferrer">
                    {project.domain}{project.ext}
                </a>
                <p>“{project.statement}”</p>
                <ul>{tasksList}</ul>
                <div className="tech-list">{technologiesList}</div>
            </section>
            <DesktopImg href={`https://${project.domain}${project.ext}`} target="_blank" rel="noopener noreferrer">
                <img 
                    className="desktop-block" 
                    src={`./projects/${project.domain}.png`} 
                    alt={project.name} 
                    loading="lazy" 
                    />
                <img 
                    className="image-2" 
                    src={`./projects/${project.domain}_2.png`} 
                    alt={project.name} 
                    loading="lazy" 
                    />
            </DesktopImg>
        </Wrapper>
    );
};


const Wrapper = styled.div`
    max-width: 1000px;

    --_pad: calc(var(--_gap)*3);
    padding: var(--_pad) 0 var(--_pad);
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }
    grid-gap: var(--_gap);
    border-bottom: 1px solid white;
    &:nth-of-type(odd) {
        > section { order: 1; }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
    }


    .tech-list {
        span::after {
            content ' | ';
        }
        span:last-of-type::after {
            content: '';
        }
    }

    > section {
        display: flex;
        flex-direction: column;
        h2 {
            margin-bottom: 0;
        }
        ul {
            flex-grow: 1;
            margin-block-start: 0;
            // margin-block-end: 0;
        }
    }






    // animation for interestionObserver class.remove('hide-intersecion')
    opacity: 1;
    margin: 0 auto;
    transition: 1.6s ease;
    &.hide-unobserved {
        opacity: 0!important;
        margin-top: 20px!important;
        transition: 1s ease;
    }
`;
const DesktopImg = styled.a`
    display: inline-block;
    position: relative;
    @media screen and (max-width: 900px) {
        display: none;
    }
    .image-2 {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: .3s ease;
    }
    &:hover {
        .image-2 {
            opacity: 1;
        }
    }
`;

const MobileImg = styled.a`
    display: none;
    @media screen and (max-width: 900px) {
        display: block;
    }
`;

export default ProjectCard;