// HIDE FAQ and FontList for now
// update FAQ, change style to match new style.
// create generic style for all pages (hieght: 80px; etc.)
// replace pageStyle state with designStyle class

//====================================> Create a sort option for customers to view 
// "High Budget to Low Budget"
// "Full Websites First"
// "Newest to Oldest"


import React, { Component }           from 'react';
import { Routes, Route, Link }        from 'react-router-dom';
import styled                         from 'styled-components';

import { ReactComponent as LogoSVG }  from './svg/brand/logo.svg';

import * as routes                    from './constants/routes';
import ModalWindow                    from './components/Modal';
import NavMenu                        from './components/nav/HamburgerMenu';
import NavBar                         from './components/nav/NavBar';

// ==> Pages
import AboutPage                      from './pages/about';
import HomePage                       from './pages/home';
import ServicesPage                   from './pages/services';
import PortfolioPage                  from './pages/portfolio';
import ContactPage                    from './pages/contact';
import EmailConfirmation              from './components/sections/email/EmailConfirmation';
// import FAQPage                        from './components/pages/faq';

import { ReactComponent as Mail }     from './svg/icons/iconfinder_mail.svg';
// import FontList                       from './fonts';

export default class App extends Component {
  state = {
    projects: [{
      name: `Momo[Muscle]`,
      version: `4.0`,
      value: `high`,
      startDate: 2020,
      lastUpdated: 2024,
      endDate: null,
      domain: `momomuscle`,
      ext: `.com`,
      statement: `Basically, I design tools to teach women & AFAB babes to see sustainable results in less time.`,
      work: [
              `build`,
              `update`,
            ],
      tasks: [
              `Develop eCommerce website`,
              `Custom quiz w/ Klaviyo data capture`,
              `Client friendly custom template`,
              `Graphic design & Photoshop`
            ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `Photoshop`,
              `FIGMA`,
              `Klaviyo`,
            ]
  },{
    name: `Scared But Alive`,
    version: `3.2`,
    value: `high`,
    startDate: 2021,
    lastUpdated: 2024,
    endDate: null,
    domain: `scaredbutalive`,
    ext: `.com`,
    statement: `Where street meets fitness. Unisex clothing with specific fit for AFAB, non-binary + women.`,
    work: [
            `build`,
            `update`,
          ],
    tasks: [
            `Develop eCommerce website`,
            `Client editable sections`,
            `Interactive components`,
          ],
    technologies: [
            `Shopify`,
            `Liquid`,
            `Photoshop`,
            `FIGMA`,
            `Klaviyo`,
          ]
  },{      
    name: `Covered Perfectly`,
    version: `2.0`,
    value: `high`,
    startDate: 2022,
    lastUpdated: 2023,
    endDate: 2024,
    domain: `coveredperfectly`,
    ext: `.com`,
    statement: `Because looking beautiful never gets old.`,
    work: [
            `remodel`,
            `update`,
          ],
    tasks: [
            `Re-design & devlop store & brand`,
            `MailChimp email marketing`,
            `Social media graphic design`
          ],
    technologies: [
            `Shopify`,
            `Liquid`,
            `Photoshop`,
            `MailChimp`,
            `FIGMA`,
          ]
      },{
        name: `Pirooz`,
        version: `2.0`,
        value: `med`,
        startDate: 2024,
        lastUpdated: 2024,
        endDate: null,
        domain: `pirooz`,
        ext: `.com`,
        statement: `Harnessing the power of 40 years of combined experience and lifetimes of wisdom, we embrace happiness as a catalyst for impact.`,
        work: [
                `update`,
              ],
        tasks: [
                `Simple website updates`,
              ],
        technologies: [
                `WordPress`,
                `PHP`,
                `FIGMA`,
              ],
            },{
              name: `D I V I N I T Y`,
              version: `2.4`,
              value: `low`,
              startDate: 2021,
              lastUpdated: 2023,
              endDate: null,
              domain: `divinityray`,
              ext: `.com`,
              statement: `With a passion for beauty, fashion and mental health, Divinity offers a refreshing approach to the world of social media.`,
              work: [
                      `build`,
                      `update`,
                    ],
              tasks: [
                      `Design & develop Shopify website`,
                      `Graphic design`,
                      `Dropshipping integration`
                    ],
              technologies: [
                      `Shopify`,
                      `Liquid`,
                      `Photoshop`,
                      `MailChimp`,
                      `FIGMA`,
                    ]
          },{
              name: `Metamorphosis Medical Center`,
              version: `1.1`,
              value: `low`,
              startDate: 2021,
              lastUpdated: 2022,
              endDate: null,
              domain: `metatranshormone`,
              ext: `.com`,
              statement: `Our MISSION is to provide a safe, nurturing environment in which patients may access personalized therapies delivered at the highest standard of care, enabling them to heal and transform into their full potential for health and well-being.`,
              work: [
                      `build`
            ],
              tasks: [
                      `Custom single-page website`
            ],
              technologies: [
                      `Shopify`,
                      `Liquid`,
                      `SVG`,
                      `Photoshop`,
                      `MailChimp`,
                      `FIGMA`,
                    ],
    },{
      name: `Skip Shape`,
      version: `1.0`,
      value: `med`,
      startDate: 2023,
      lastUpdated: 2024,
      endDate: null,
      domain: `skip-shape`,
      ext: `.com`,
      statement: `Unlock the best version of yourself with my A.G.E. [Attract Good Energy] Strength & Endurance Program.`,
      work: [
              `build`,
              `product`,
              `update`,
            ],
      tasks: [
              `Design & develop eCommerce website`,
              `Product & Brand Development`,
              `MailChimp Connection`,
              `Photoshop Graphic Design`,
            ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `Photoshop`,
              `FIGMA`,
              `MailChimp`,
            ],
          },{
            name: `Tarotfied`,
            version: `3.0`,
            value: `low`,
            startDate: 2023,
            lastUpdated: 2023,
            endDate: null,
            domain: `tarotfied`,
            ext: `.com`,
            type: [`design, build`
          ],
            statement: `Manifest answers pulling your own cards on a layout`,
            tasks: [
                    `Design & develop website`,
                    `Deck & Card function`,
                  ],
            technologies: [
                    `React`,
                    `Firebase`,
                    `Photoshop`,
                    `FIGMA`,
                  ]
                },{
    name: `Wescare Home Health Providers`,
    version: `1.0`,
    value: `low`,
    startDate: 2024,
    lastUpdated: 2024,
    endDate: null,
    domain: `wescarehomehealth`,
    ext: `.com`,
    statement: `Known for dedicated & quality care within the home.`,
    work: [
            `build`,
            `update`,
          ],
    tasks: [
            `React template repurpose`,
            `Firbase hosting`,
            `MailChimp Connection`,
            `Photoshop Graphic Design`,
          ],
    technologies: [
            `React`,
            `Firebase`,
            `Photoshop`,
            `FIGMA`,
            `MailChimp`,
          ],
},{
    name: `Faith & Hope Home Health`,
    version: `1.0`,
    value: `low`,
    startDate: 2024,
    lastUpdated: 2024,
    endDate: null,
    domain: `faithandhopehomehealth`,
    ext: `.com`,
    statement: `Known for dedicated & quality care within the home.`,
    work: [
            `build`,
            `update`,
          ],
    tasks: [
            `Design & Develop basic website`,
            `Free firebase hosting`,
            `Contact email setup`,
          ],
    technologies: [
            `React`,
            `Firebase`,
            `Photoshop`,
            `FIGMA`,
            `MailChimp`,
          ]
},{
    name: `Kimberly Charter Ministries`,
    version: `1.0`,
    value: `low`,
    startDate: 2023,
    lastUpdated: 2023,
    endDate: null,
    domain: `kimberlycharter`,
    ext: `.com`,
    type: [`build, contract, remodel, update, upgrade, maintain`],
    statement: `Kimberly has brought the world of fellowship and friendship to a new platform...`,
    tasks: [
            `Design & develop website`,
            `Firbase hosting`,
            `MailChimp Connection`,
            `Photoshop Graphic Design`,
          ],
    technologies: [
            `React`,
            `Firebase`,
            `MailChimp`,
            `Photoshop`,
            `FIGMA`,
          ]
},{
      name: `Flawed™️`,
      version: `2.0`,
      value: `med`,
      startDate: 2023,
      lastUpdated: 2023,
      endDate: null,
      domain: `dateflawed`,
      ext: `.com`,
      statement: `Flawed™ is the first dating app where you can find what you want by eliminating what you don't.`,
      work: [
              `work-in-progress, remodel, upgrade`
    ],
      tasks: [
              `WordPress website relaunch`,
              `Update match-making quiz`
            ],
      technologies: [
              `WordPress`,
              `PHP`,  
              `DateBook`,
            ]
  },{
      name: `Red5 Performance`,
      version: `2.6`,
      value: `high`,
      startDate: 2021,
      lastUpdated: 2024,
      endDate: null,
      domain: `red5performance`,
      ext: `.com`,
      statement: `THE EQUATION IS SIMPLE: PHYSICS + DISCIPLINE + RED5 = HAPPIER, HEALTHIER, STRONGER YOU`,
      work: [
              `build`,
              `maintain`
            ],
      tasks: [
              `Design & develop eCommerce website`,
              `MailChimp campaigns`,
              `Photoshop, graphics design`,
              `Digital product design & development`,
              `Marketing strategic planning`
            ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `MailChimp`,
              `Photoshop`,
              `FIGMA`,
            ]
  },{
      name: `Nexus Housing`,
      version: `1.2`,
      value: `high`,
      startDate: 2023,
      lastUpdated: 2023,
      endDate: null,
      domain: `nexushousing`,
      ext: `.org`,
      statement: `Sustainable modular housing.`,
      work: [
              `build`,
              `update`,
            ],
      tasks: [
              `Design & develop website`,
              `Custom editable Liquid sections`
            ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `Photoshop`,
              `FIGMA`,
            ]
 
  },{
      name: `Our Highest Mantra`,
      version: `1.0`,
      value: `med`,
      startDate: 2023,
      lastUpdated: 2023,
      endDate: 2023,
      domain: `ourhighestmantra`,
      ext: `.com`,
      statement: `Find your higher self.`,
      work: [
              `build`
    ],
      tasks: [
              `Design & develop promotional landing page`,
              `Integrated 3rd part App`,
              `Photoshop Edits to Logo`
            ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `SVG`,
              `Photoshop`,
              `FIGMA`,
            ]
  },{
      name: `Prana Wellness`,
      version: `5.2`,
      value: `high`,
      startDate: 2019,
      lastUpdated: 2023,
      endDate: 2023,
      domain: `pranawellnessworks`,
      ext: `.com`,
      statement: `Prana is Sanskrit for breath, considered as a life-giving force.  Prana is seen as a universal energy, which flows in currents in and around the body.`,
      work: [
              `build`,
              `update`,
            ],
      tasks: [
              `Design & develop website & blog`,
              `MailChimp email automation`,
              `Photoshop`
            ],
      technologies: [
              `React`,
              `Firebase`,
              `Photoshop`,
              `MailChimp`,
              `FIGMA`,
            ],
  },{
      name: `Levi Eiko Designs`,
      version: `2.4`,
      value: `high`,
      startDate: 2019,
      lastUpdated: 2023,
      endDate: null,
      domain: `levieikodesigns`,
      ext: `.com`,
      statement: `Build your new website or upgrade an existing one.`,
      work: [
              `build`,
              `maintain`
            ],
      tasks: [
              `Fully coded website`,
              `Built with React`,
              `Firebase Platform`
            ],
      technologies: [
              `React`,
              `Firebase`,
              `Photoshop`,
              `MailChimp`,
              `FIGMA`,
            ]
  },{
      name: `Dizzy with Excitement`,
      version: `1.1`,
      value: `high`,
      startDate: 2023,
      lastUpdated: 2024,
      endDate: null,
      domain: `dizzywithexcitement`,
      ext: `.com`,
      statement: `Endless options printed on demand.`,
      work: [
              `upgrades`
    ],
      tasks: [
        `Custom Product variables`,
        `Wallpaper product size/price calculator`,
        `Custom cart and packing slip information`,
        `Custom Collections color swatch`,
    ],
      technologies: [
              `Shopify`,
              `Liquid`,
            ]
  },{
      name: `Sathi Roy`,
      version: `2.0`,
      value: `high`,
      startDate: 2021,
      lastUpdated: 2023,
      endDate: 2023,
      domain: `sathiroy`,
      ext: `.com`,
      statement: `I help people reach their highest potential every day by taking a holistic and customized approach involving their mind, body, soul, and spirit.`,
      work: [
              `build`,
              `product`,
    ],
      tasks: [
              `Customized eCommerce website`,
              `Photoshop graphic design`,
              `Vector/digital graphics animations`
            ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `SVG`,
              `Photoshop`,
              `FIGMA`,
            ],
  },{
      name: `Baddie by Divinity`,
      version: `1.6`,
      value: `high`,
      startDate: 2021,
      lastUpdated: 2022,
      endDate: 2022,
      domain: `baddiebydivinity`,
      ext: `.com`,
      statement: `Baddie By Divinity is here to help us find and embrace our badassery, no matter what form it takes and no matter how we show it to the world.`,
      work: [
              `build`
    ],
      tasks: [
              `Customized eCommerce website`,
              `Graphic design & touchups`,
              `Email marketing & automation`
    ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `SVG`,
              `Photoshop`,
              `MailChimp`,
              `FIGMA`,
            ]
  },{
      name: `Bravo Built`,
      version: `1.1`,
      value: `med`,
      startDate: 2021,
      lastUpdated: 2022,
      endDate: 2022,
      domain: `bravobuiltfit`,
      ext: `.com`,
      statement: `Within these programs you will find a constant changing routine that helps build a brick by brick foundation.`,
      work: [
              `build`
    ],
      tasks: [
              `Customized eCommerce website`,
              `MailChimp email automation`,
              `Photoshop graphic design`,
              `Marketing strategic planning`,
              `Merch/product design`
            ],
      technologies: [
              `Shopify`,
              `Liquid`,
              `Photoshop`,
              `FIGMA`,
              `MailChimp`,
            ]
  },{
      name: `Andrea Sells Maui`,
      version: `1.5`,
      value: `high`,
      startDate: 2020,
      lastUpdated: 2021,
      endDate: 2021,
      domain: `andreasellsmaui`,
      ext: `.com`,
      statement: `Maui real estate agent serving her local community`,
      work: [
              `build`,
    ],
      tasks: [
              `Customized real estate website`,
              `Photoshop graphic design`,
              `Ad creation` 
            ],
      technologies: [
              `WordPress`,
              `Photoshop`,
              `IDX Broker`,
              `FIGMA`,
            ]
  },{          
      name: `FITPLAN`,
      version: `0.0`,
      value: `high`,
      startDate: 2019,
      lastUpdated: 2020,
      endDate: 2020,
      domain: `fitplanapp`,
      ext: `.com`,
      statement: `Workout with elite personal trainers.`,
      work: [
              `contract`,
              `update`,
            ],
      tasks: [
              `Custom email: design & marketing`,
              `Web development, design & troubleshooting`,
              `Photoshop graphic design`
    ],
      technologies: [
              `React`,
              `Shopify`,
              `Liquid`,
              `WordPress`,
              `Photoshop`,
              `Figma`,
              `MailChimp`,
            ]
  },{
      name: `IT After Hours`,
      version: `0.0`,
      value: `med`,
      startDate: 2019,
      lastUpdated: 2020,
      endDate: 2020,
      domain: `it-afterhours`,
      ext: `.com`,
      statement: `Professional, affordable computer service company`,
      work: [
              `remodel`
    ],
      tasks: [
              `Streamline WordPress & resolve bugs`,
              `Customize CSS code`,
              `Mobile friendly upgrade`
    ],
      technologies: [
              `WordPress`,
              `FIGMA`,
            ]
  },{
      name: `HeatSeeker Hot Sauce`,
      version: `0.0`,
      value: `high`,
      startDate: 2019,
      lastUpdated: 2020,
      endDate: 2020,
      domain: `heatseekersauce`,
      ext: `.com`,
      statement: `Bold and minimal, pure and simple... That's what we do.`,
      work: [
              `remodel`,
              `upgrade`
            ],
      tasks: [
              `Re-design Shopify website`,
              `Streamline content & UX`,
              `Add wholesale accounts`
            ],
      technologies: [
              `Shopify`,
              `Photoshop`,
              `FIGMA`,
            ]
  },{
      name: `La Madre is Watching`,
      version: `1.0`,
      value: `med`,
      startDate: 2007,
      lastUpdated: 2009,
      endDate: 2009,
      domain: `lamadreiswatching`,
      ext: `.com`,
      statement: `Photographing the edgy side of the Los Angeles night scene.`,
      work: [
              `build`,
              `maintain`,
            ],
      tasks: [
              `Social media website`,
              `Shareable photo gallery`,
              `Graphic design`
    ],
      technologies: [
              `HTML`,
              `CSS`,
              `Javascript`,
              `Photoshop`,
            ]
  }
],
    modalType: ``,   
    pageStyle: {
      home: {
        title: ``,
        color: `#fff`,
        displayLogo: `none`,
      },
      about: {
        title: ``,
        color: `var(--hex-blue)`,
        displayLogo: `none`,
      },
      portfolio: {
        title: `portfolio`,
        color: `#fff`,
        displayLogo: `inline-block`,
      },
      faq: {
        title: `faq`,
        color: `#181717`,
        displayLogo: `inline-block`,
      },
      services: {
        title: `contact`,
        color: `#fff`,
        displayLogo: `none`,
      },
      mail: {
        title: `contact`,
        color: `#fff`,
        displayLogo: `inline-block`,
      },
      root: {
        title: ``,
        color: `#fff`,
        displayLogo: `none`,
      }
    },
    slideMe: ``,
  };

  setslideMe = async () => {
    this.setState({
      slideMe: 'slideme',
    });
  };

  animateSlideMe = async () => {
    await this.setslideMe();
    setTimeout(() => {
      this.setState({
        slideMe: '',
      });
    }, 1000);
  };

  toggleModal = (e) => {
    const toggle = document.getElementById('modal');
    toggle.classList.toggle('active');
    toggle.classList.toggle('inactive');
    this.setState({
      modalType: e.currentTarget.value
    });
  };
  // Toggle Hamburger Menu
  btnHamburger =() => {
    document.getElementById('hamburger-btn').classList.toggle('hamburger-close');
  }
  toggleMenu = () => {
    const hamburgerMenu = document.getElementById('menu');
    hamburgerMenu.classList.toggle('active');
    hamburgerMenu.classList.toggle('inactive');
  };
  btnScroll = () => {
    const scrollTo = document.getElementById('scroll-to'); 
    scrollTo.scrollIntoView({ behavior: 'smooth'});              
  }; 
  render (){
    const { modalType, pageStyle, projects, slideMe } = this.state;

    return (
      <AppContainer>
        <LogoSVG className="establish-logo" style={{width: '0', height: '0', position: 'absolute'}} />
        <NavMenu btnHamburger={this.btnHamburger} resetslideMe={this.resetslideMe} toggleMenu={this.toggleMenu} animateSlideMe={this.animateSlideMe} />
        <ModalWindow contactType={modalType} toggleModal={this.toggleModal} />
        <Routes>
            <Route path={routes.MAIL} exact element={<>
              <GoblinValleyImageAnimation/>
              </>}/>
            <Route path={routes.CNFM} exact element={<>
              <HeroImageDivAnimation/>
              <ContactMe><Link to={routes.MAIL}><Mail/></Link></ContactMe>
              </>}/>
            <Route path={routes.PORT} exact element={<>
              <ContactMe><Link to={routes.MAIL}><Mail/></Link></ContactMe>
              </>}/>
            <Route path={routes.INFO} exact element={<>
              <ContactMe><Link to={routes.MAIL}><Mail/></Link></ContactMe>
              </>}/>
            {/* <Route path={routes.FAQS} exact element={<>
              <ContactMe><Link to={routes.MAIL}><Mail/></Link></ContactMe>
              </>}/> */}
            <Route path={routes.SERV} exact element={<>
              <ContactMe><Link to={routes.MAIL}><Mail/></Link></ContactMe>
              </>}/>
            <Route path={routes.ROOT} exact element={<>
              <HeroImageDivAnimation/>
              <ContactMe><Link to={routes.MAIL}><Mail/></Link></ContactMe>
              </>}/>
            <Route path={routes.ROOT} element={<>
              <HeroImageDivAnimation/>
              <ContactMe><Link to={routes.MAIL}><Mail/></Link></ContactMe>
              </>}/>
        </Routes>

        <BodyContainer>
          <ContentContainer>
            <Routes>         
              {/* <Route path={routes.FONT} exact element={<FontList/> }/> */}
              <Route path={routes.MAIL} exact element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="white"/>
                <ContactPage contactType={modalType}/>
                </>}/>
              <Route path={routes.CNFM} exact element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="home"/>
                <EmailConfirmation/>
                </>}/>
              <Route path={routes.PORT} exact element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="white"/>
                <PortfolioPage projects={projects}/>
                </>}/>
              <Route path={routes.INFO} exact element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="info"/>
                <AboutPage slideMe={slideMe} animateSlideMe={this.animateSlideMe}/>
                </>}/>
              {/* <Route path={routes.FAQS} exact element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="blue"/>
                <FAQPage/>
                <Footer/>
                </>}/> */}
              <Route path={routes.SERV} exact element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="white"/>
                <ServicesPage pageStyle={pageStyle.portfolio}/>
                </>}/>
              <Route path={routes.ROOT} exact element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="home"/>
                <HomePage btnScroll={this.btnScroll} pageStyle={pageStyle.home} projects={projects}/>
                </>}/>
              <Route path={routes.ROOT} element={<>
                <NavBar btnHamburger={this.btnHamburger} toggleMenu={this.toggleMenu} designStyle="home"/>
                <HomePage btnScroll={this.btnScroll} pageStyle={pageStyle.home}/>
                </>}/>
            </Routes>
          </ContentContainer>
        </BodyContainer>   
      </AppContainer>
    );
  };
};

const ContactMe = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 12px;
  right: 12px;
  width: 48px;
  a svg { 
    fill: var(--hex-blue);
    transition: transform .3s ease;
  }
  a:hover svg {
    transform: scale(1.1);
    transition: transform .1s linear;
  }
  a:active svg {
    transform: scale(1.1) translate(0px,2px);
  }
`;

const ContentContainer = styled.div`
  color: #fff;
  height: 100vh;
  position: relative;
`;

const BodyContainer = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;
const AppContainer = styled.div`
  h1 {
    text-align: center;
  }
`;
const HeroImageDiv = styled.div`
  height: 100vh;
  width: 100vw;

  background-image: url(background/header-hero.png);
  background-size: cover;
  
  position: fixed;
  z-index: -100;

  @media screen and (max-width: 900px){
    background-position: 23%;
  }
`;
const HeroImageDivAnimation = styled(HeroImageDiv)`
  background-repeat: repeat-x;
  animation: slideleft 900s infinite linear;
`;
const GoblinValleyImage = styled.div`
  height: 100vh;
  width: 100vw;

  background-image: url(background/goblin-valley.jpg);
  background-size: cover;
  
  position: fixed;
  z-index: -100;

  @media screen and (max-width: 900px){
    background-position: 23%;
  }
`;
const GoblinValleyImageAnimation = styled(GoblinValleyImage)`
  background-repeat: repeat-x;
  animation: slideleft 1200s infinite linear;
`;