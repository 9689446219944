import React        from 'react';
import styled       from 'styled-components';

// Insert card as independent function to enable scroll into view for each session
import MedProjectCard  from './MedProject';

const MedProjects = ({ projects }) => {
    const liveProjects = projects.filter(project => (project.endDate === null && project.value === 'med'));
    const showProjects = liveProjects.map((project, key) => {
        return( <MedProjectCard key={key} project={project}/> );
    });
    return(
        <Container>
            {showProjects}          
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
    max-width: 1000px;
    margin: 40px auto;
    
    img {
        object-fit: cover;
        object-position: top;
    }
    @media screen and (max-width: 640px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        max-width: 1000px;
        margin: 40px auto;
        padding: 0 10px;
    }

`;

export default MedProjects;