import React  from 'react';
import styled from 'styled-components';

import LowProject from './LowProject';

const LowProjects = ({ projects }) => {
    const LowProjects = projects.filter(project => project.endDate === null && project.value === `low`);
    const mapLowProjects = LowProjects.map((project, key) => {
        return( <LowProject project={project} key={key}/> )
    });
    return(
        <Container>
            {mapLowProjects}                
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    text-align: center;
    gap: 10px;

    // display: flex;
    // flex-wrap: wrap;
    align-items: start;
    justify-content: center;

    @media screen and (max-width: 640px) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding: 0 10px;
    }
`;




export default LowProjects;